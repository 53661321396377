import React from 'react';
import ChartBarStacked from '../../Common/Charts/ChartBarStacked';

export default function ChartBar({data, colors, lines}) {
	const chartConfig = {
		decimals: 2,
	};

	return (
		<div className="chartBar">
			<ChartBarStacked config={chartConfig} data={data} colors={colors} lines={lines} />
		</div>
	);
}
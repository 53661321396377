import React from 'react';
import utils from '../../../utils';

/**
 * Button that is used for report actions
 *
 * @export
 * @param {String} label
 * @param {String} icon
 * @param {String} iconColor
 * @param {Function} onClick
 *
 * @return {JSX}
 */
export default function ButtonReport({
	label,
	icon,
	iconColor,
	onClick,
}) {
	const translatedLabel = utils.translate(label);

	return (
		<button onClick={(e) => onClick ? onClick(e) : null} className="buttonReport">
			<span style={{color: iconColor}} className={`iconfas-${icon} iconfas-${icon} ${label ? 'buttonReport__withLabel' : ''}`} />
			{label ? translatedLabel : null}
		</button>
	);
}
import React, {useState, useEffect} from 'react';
import ActionButtons from '../ActionButtons/ActionButtons';
import FileField from '../FormElements/FileField';
import useAction from '../../store/actions';
import utils from '../../utils';
import ButtonBasic from '../Common/Buttons/ButtonBasic';
import toast from 'react-hot-toast';
import Switch from '../FormElements/Switch';
import { useNavigate } from 'react-router-dom';
import Select from '../FormElements/Select';

/**
 * @export
 * @param {Object} viewData
 * @param {Object} translate
 *
 * @return {JSX}
 */
export default function Import({viewData, translate}) {
	const sAction = useAction();
	const debugMode = localStorage.getItem('debugMode') ? true : false;
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();
	const [file, setFile] = useState(null);
	const [settings, setSettings] = useState({ignoreFirstLine: 1, importNotFound: 1, updateIfFound: 1});
	const [importData, setImportData] = useState(null);
	const [importColumns, setImportColumns] = useState({});
	const [importResult, setImportResult] = useState('');

	/**
	 * Set value of settings state
	 *
	 * @param {String} name
	 * @param {*} value
	 */
	const setSettingsValue = (name, value) => {
		setSettings({...settings, [name]: value});
	};

	useEffect(() => {
		clickForUpload();
	}, []);

	/**
	 * Upload csv file for import
	 *
	 * @param {*} e
	 * @return {*}
	 */
	const upload = (e) => {
		e.preventDefault();

		if (!e.target.files[0]) {
			return;
		}

		sAction.load();

		const formData = {};
		formData.module = viewData?.module;
		formData.file = e.target.files[0];

		utils.post('importList', formData).then((res) => {
			if (res.status) {
				setImportData(res.data);
			} else {
				toast.error(res.dataResult);
			}
			sAction.unload();
		}).catch((error) => {
			console.error(error);
		});

		setFile(e.target.files[0]);
	};

	/***/
	const clickForUpload = () => {
		const fileInput = document.getElementById('importFile');

		if (fileInput) {
			fileInput.click();
		}
	};

	const errorRequiredColumn = utils.translate('LBL_IMPORT_ERROR_COLUMN_REQUIRED');
	const successLabel = utils.translate('LBL_IMPORT_SUCCESS');

	/**
	 * Function for import
	*/
	const startImport = () => {
		let isRequiredColumnSet = false;
		Object.keys(importColumns).map((key) => {
			const column = importColumns[key];
			if (column === importData?.columnsDBIdentify?.name) {
				isRequiredColumnSet = true;
			}
		});

		if (!isRequiredColumnSet) {
			return toast.error(errorRequiredColumn);
		}

		if (!file) {
			return;
		}

		sAction.load();

		const params = {
			module: viewData?.module,
			settings,
			filePath: importData.filePath,
			importColumns,
		};

		utils.post('startImportList', params).then((res) => {
			if (!res.status) {
				toast.error(res.dataResult);
			} else {
				setImportResult(res.data.dataProtocol);
				toast.success(successLabel);
			}
			sAction.unload();
		}).catch((error) => {
			console.error(error);
		});
	};

	/**
	 * @return {Object}
	 */
	const columnsList = () => {
		const selectValues = {'': ''};
		Object.keys(importData?.columnsDB).forEach((key) => {
			const column = importData?.columnsDB?.[key];
			selectValues[key] = translate[column.label];
		});

		return selectValues;
	};

	/**
	 * Render header of the csv file
	 *
	 * @return {JSX}
	 */
	const renderHeader = () => {
		return importData?.columnsFile?.[0]?.map((column, i) => {
			return (
				<th key={i}>
					<Select
						onChange={(e) => setImportColumns({...importColumns, [i]: e.target.value})}
						value={importColumns[i]}
						fieldName="quotePrintVariant"
						options={columnsList()}
					/>
				</th>
			);
		});
	};

	/**
	 * Function that renders example rows of the file
	 *
	 * @return {JSX}
	 */
	const renderFileData = () => {
		return importData?.columnsFile?.map((row, rowIndex) => {
			return (
				<tr className={`import__table-row ${settings.ignoreFirstLine && rowIndex === 0 ? 'import__table-disabled' : ''}`} key={rowIndex}>
					{row.map((column, i) => {
						return <td key={i} className={`${(settings.ignoreFirstLine && rowIndex === 0) || importColumns[i] ? '' : 'import__table-disabled'}`}>{column}</td>;
					})}
				</tr>
			);
		});
	};

	const columnIdentifyTranslate= utils.translate('LBL_MODULE_COLUMN_IDENTIFY');
	const columnIdentifyValueTranslate = utils.translate(importData?.columnsDBIdentify?.label);
	const ignoreLabel = utils.translate('LBL_IGNORE_FIRST_LINE');
	const notFoundLabel = utils.translate('LBL_IMPORT_NOT_FOUND');
	const updateNotFoundLabel = utils.translate('LBL_UPDATE_IF_FOUND');
	const protocolLabel = utils.translate('LBL_IMPORT_PROTOCOL');
	const infoLabel = utils.translate('LBL_IMPORT_INFO');

	return (
		<>
			<ActionButtons className={'import__buttons'}>
				<ButtonBasic
					style="green"
					icon="start"
					label="LBL_START_IMPORT"
					onClick={startImport}
					disabled={file ? false : true}
				/>
				<ButtonBasic
					icon="upload"
					label="LBL_UPLOAD_IMPORT_FILE"
					onClick={clickForUpload}
				/>
				<ButtonBasic
					icon="leave"
					label="LBL_BACK"
					onClick={() => navigate(`/${urlPrefix}/list/${viewData.module}`)}
				/>
			</ActionButtons>
			<div className="import">
				<div className="import__fileField">
					<FileField
						value={file}
						fieldName="importFile"
						label={'LBL_IMPORT_FILE'}
						acceptedFiles={['csv']}
						module={viewData?.module}
						canDownload={false}
						onChange={(e) => upload(e)}
					/>
				</div>
				{file ? <>
					<div className="import__info" dangerouslySetInnerHTML={{__html: infoLabel}} />
					{importData?.columnsDBIdentify ?
						<div className="import__identifyColumn">
							{columnIdentifyTranslate}: <span>{columnIdentifyValueTranslate} {debugMode ? `[${importData?.columnsDBIdentify?.name}]` : ''}</span>
						</div> : null}
					<div className="import__settings">
						<Switch
							onChange={(e) => setSettingsValue('ignoreFirstLine', e.target.checked ? 1 : 0)}
							checked={!!settings.ignoreFirstLine}
							label={ignoreLabel}
						/>
						<Switch
							onChange={(e) => setSettingsValue('importNotFound', e.target.checked ? 1 : 0)}
							checked={!!settings.importNotFound}
							label={notFoundLabel}
						/>
						<Switch
							onChange={(e) => setSettingsValue('updateIfFound', e.target.checked ? 1 : 0)}
							checked={!!settings.updateIfFound}
							label={updateNotFoundLabel}
						/>
					</div>
					<div className="import__content">
						<table className="import__table">
							<thead>
								<tr>{renderHeader()}</tr>
							</thead>
							<tbody>
								{renderFileData()}
							</tbody>
						</table>
					</div>
				</> : null}
				{importResult ?
					<>
						<div className="import__resultLabel">{protocolLabel}</div>
						<div className="import__result" dangerouslySetInnerHTML={{__html: importResult}} />
					</>
					: null}
			</div>
		</>
	);
}

import React from 'react';
import utils from '../../../../utils';

/**
 * @export
 * @param {Object} favoriteProducts
 * @param {Function} getDataForLine
 * @param {Number | null} groupIndex
 *
 * @return {*}
 */
export default function FavoriteProducts({
	favoriteProducts,
	getDataForLine,
	groupIndex,
}) {
	const colors = window.colorPalette;

	/**
     * @return {JSX}
     */
	const renderProducts = () => {
		return favoriteProducts?.map((product, index) => {
			const color = colors[index % colors?.length];
			const rgbColor = utils.hexToRgb(color); // Converts hex to RGB
			return (
				<div
					onClick={() => getDataForLine(product, groupIndex)}
					style={{
						backgroundColor: `rgba(${rgbColor}, 0.6)`,
						border: `2px solid ${color}`,
						'--color-rgb': rgbColor // Set the RGB value as a CSS variable
					}}
					className="favoriteProducts__item"
					key={product?.id}
				>
					{product?.name}
				</div>
			);
		});
	};

	return (
		<div className="favoriteProducts">
			{renderProducts()}
		</div>
	);
}

import React, {useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import utils from '../../utils';
import hardRedirect from '../../utils/hardRedirect';
import useAction from '../../store/actions';
import Tooltip from '../Common/Tooltip/Tooltip';

/**
 * @export
 * @param {Object} userData
 * @param {Function} setUserMenu
 *
 * @return {JSX}
 */
export default function UserMenu({userData, setUserMenu}) {
	const sAction = useAction();
	const userMenuRef = useRef(null);

	useEffect(() => {
		/**
		 * Handles click outside of userMenu to close it
 		 * @param {Event} event
  		 *
 		 * @return {void}
    	 */
		const handleClickOutside = (event) => {
			if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
				setUserMenu(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [userMenuRef]);

	/**
	 * Logout the user
 	 * @param {Event} else
  	 *
 	 * @return {void}
     */
	const logout = (e) => {
		e.preventDefault();
		utils
			.get('logout', true)
			.then(() => {
				// hardRedirect('/login');
			})
			.catch((error) => {
				console.error(error);
			});
	};

	/**
	 * Redirects user to myProfile settings
  	 *
 	 * @return {void}
     */
	const redirectMyProfile = () => {
		setUserMenu(false);
		sAction.dsSet('view/module', 'myProfile');
	};

	const prefix = utils.getUrlPrefix();

	return (
		<menu className="userMenu" ref={userMenuRef}>
			<div className="userMenu__header"></div>
			<div className="userMenu__user">
				{userData.photo ? (
					<div className="userMenu__profilePicture">
						<img src={userData.photo} className="userMenu__photo" />
					</div>
				) : (
					<span className="userMenu__profilePicture iconfas-user"></span>
				)}
				<div className="userMenu__box">
					<div className="userMenu__user-name">
						{`${userData.firstName} ${userData.lastName}`}
					</div>
					<Tooltip style={{backgroundColor: '#FFF', color: '#333333'}} placement="top" title={userData.username}>
						<div className="userMenu__user-username">
							{userData.username}
						</div>
					</Tooltip>
				</div>
			</div>
			<div className="userMenu__actions">
				<Link className="userMenu__actions-item" onClick={() => redirectMyProfile()} to={`${prefix}/myProfile`}>
					<div className="userMenu__actions-icon">
						<span className="iconfas-user"></span>
					</div>
					<div className="userMenu__actions-text">{utils.translate('LBL_MYPROFILE')}</div>
				</Link>
				{/* {userData.sysadmin === 1 ? (
					<div className="userMenu__actions-item" onClick={() => sAction.openPopup('RepairPopup')}>
						<div className="userMenu__actions-icon">
							<span className="iconfas-repair"></span>
						</div>
						<div className="userMenu__actions-text">{utils.translate('LBL_REPAIR')}</div>
					</div>
				) : null} */}
				<div className="userMenu__actions-item" onClick={(e) => logout(e)}>
					<div className="userMenu__actions-icon">
						<span className="iconfas-logout"></span>
					</div>
					<div className="userMenu__actions-text">{utils.translate('LBL_LOGOUT')}</div>
				</div>
			</div>
		</menu>
	);
}

import { useSelector } from 'react-redux';

/**
 * Get enum
 * @param {String} enumName - searched enum
 * @returns {Object | Void}
 */
export default function useGetEnum(enumName){
	const translates = useSelector(state => state.config.translate.enum);
	return translates[enumName] || {};
}
import React, {useState, useEffect} from 'react';
import ChartDonut from '../Common/Charts/ChartDonut.js';
import utils from '../../utils';
import ChartBarStacked from '../Common/Charts/ChartBarStacked.js';
import Loader from '../Common/Loader/Loader.js';
import useAction from '../../store/actions';

/**
 * @export
 *
 * @return {JSX}
 */
export default function Home() {
	const sAction = useAction();
	const userRoleType = sAction.dsGet('user/roleTypeId');

	const [chartQuote, setChartQuote] = useState({data: [], labels: [], colors: [], total: 0});
	const [chartOrder, setChartOrder] = useState({data: [], labels: [], colors: [], total: 0});
	const [chartQuoteSalesmen, setChartQuoteSalesmen] = useState({data: [], labels: [], colors: []});
	const [chartOrderSalesmen, setChartOrderSalesmen] = useState({data: [], labels: [], colors: []});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		setLoad(true);

		utils
			.post('getDashboardData', {module: 'dashboard'})
			.then((res) => {
				var pomQ = {data: [], labels: [], colors: [], total: 0};
				for (var i in res.data?.['donut_quote']?.['data']) {
					pomQ.data.push(parseInt(res.data['donut_quote']['data'][i]));
					pomQ.labels.push(res.data['donut_quote']['labels'][i]);
					pomQ.colors.push(res.data['donut_quote']['colors'][i]);
					pomQ.total += parseInt(res.data['donut_quote']['data'][i]);
				}
				setChartQuote({data: pomQ.data, labels: pomQ.labels, colors: pomQ.colors, total: pomQ.total});

				pomQ = {data: [], labels: [], colors: [], total: 0};
				for (var j in res.data['donut_order']['data']) {
					pomQ.data.push(parseInt(res.data['donut_order']['data'][j]));
					pomQ.labels.push(res.data['donut_order']['labels'][j]);
					pomQ.colors.push(res.data['donut_order']['colors'][j]);
					pomQ.total += parseInt(res.data['donut_order']['data'][j]);
				}
				setChartOrder({data: pomQ.data, labels: pomQ.labels, colors: pomQ.colors, total: pomQ.total});

				pomQ = {lines: [], data: [], labels: [], colors: []};
				for (var k in res.data['bar_quote']['labels']) {
					pomQ.colors.push(res.data['bar_quote']['colors'][k]);
					pomQ.data.push({name: res.data['bar_quote']['labels'][k], data: res.data['bar_quote']['data']['data'][k]});
				}
				setChartQuoteSalesmen({lines: res.data['bar_quote']['data']['name'], data: pomQ.data, colors: pomQ.colors});

				pomQ = {lines: [], data: [], labels: [], colors: []};
				for (var l in res.data['bar_order']['labels']) {
					pomQ.colors.push(res.data['bar_order']['colors'][l]);
					pomQ.data.push({name: res.data['bar_order']['labels'][l], data: res.data['bar_order']['data']['data'][l]});
				}
				setChartOrderSalesmen({lines: res.data['bar_order']['data']['name'], data: pomQ.data, colors: pomQ.colors});

				setLoad(false);
			})
			.catch((error) => {
				console.error(error);
				setLoad(false);
			});
	}, []);

	let lblQuote = utils.translate('LBL_QUOTE');
	let lblSalesman = utils.translate('LBL_SALESMEN');
	let lblOrders = utils.translate('LBL_ORDER');

	let renderChartQuoteLegend = [];
	let renderChartOrderLegend = [];

	chartQuote.labels?.forEach((item, index) => {
		renderChartQuoteLegend.push(
			<div key={index} className="chartdonut__legend__main">
				<div className="chartdonut__legend__rec" style={{backgroundColor: chartQuote.colors[index]}}></div>
				<div className="chartdonut__legend__iteml">{item}</div>
				<div className="chartdonut__legend__itemr">
					<b>{chartQuote.data[index]}</b>
				</div>
				<div className="ln00"></div>
			</div>
		);
	});

	chartOrder.labels?.forEach((item, index) => {
		renderChartOrderLegend.push(
			<div key={index} className="chartdonut__legend__main">
				<div className="chartdonut__legend__rec" style={{backgroundColor: chartOrder.colors[index]}}></div>
				<div className="chartdonut__legend__iteml">{item}</div>
				<div className="chartdonut__legend__itemr">
					<b>{chartOrder.data[index]}</b>
				</div>
				<div className="ln01"></div>
			</div>
		);
	});

	return (
		<div className="overview__container">
			<div className="overview">
				{load ? (
					<div className="chartloader">
						<Loader transparent={true} />
					</div>
				) : null}

				<div className="overview__content">
					<div className="chartdonut__main">
						<span className="chartdonut__header">
							<b>{lblQuote}</b>
						</span>
						<div className="ln05"></div>
						<div className="chartdonut__legend">{renderChartQuoteLegend}</div>
						<div className="chartdonut__chart">
							<b>{chartQuote.total}</b>
						</div>
						{chartQuote.data?.length > 0 &&
							<ChartDonut key="quote" data={chartQuote.data} labels={chartQuote.labels} colors={chartQuote.colors} />
						}
					</div>

					{userRoleType > 10 && <div className="chartcolumn__main">
						<span className="chartcolumn__header">
							<b>
								{lblQuote} - {lblSalesman}
							</b>
						</span>
						{chartQuoteSalesmen.data?.length > 0 &&
							<ChartBarStacked key="quote_salesman" lines={chartQuoteSalesmen.lines} data={chartQuoteSalesmen.data} colors={chartQuoteSalesmen.colors} />
						}
					</div>}

					<div className="chartdonut__main">
						<span className="chartdonut__header">
							<b>{lblOrders}</b>
						</span>
						<div className="ln05"></div>
						<div className="chartdonut__legend">{renderChartOrderLegend}</div>
						<div className="chartdonut__chart">
							<b>{chartOrder.total}</b>
						</div>
						{chartOrder.data?.length > 0 &&
							<ChartDonut key="order" data={chartOrder.data} labels={chartOrder.labels} colors={chartOrder.colors} />
						}
					</div>

					{userRoleType > 10 &&<div className="chartcolumn__main">
						<span className="chartcolumn__header">
							<b>
								{lblOrders} - {lblSalesman}
							</b>
						</span>
						<div className="ln10"></div>
						{chartOrderSalesmen.data?.length > 0 &&
							<ChartBarStacked key="order_salesman" lines={chartOrderSalesmen.lines} data={chartOrderSalesmen.data} colors={chartOrderSalesmen.colors} />
						}
					</div>}
				</div>
			</div>
		</div>
	);
}

/**
 *
 * @export
 * @param {Array} products
 * @param {Object} def
 * @param {*} initLines
 * @param {Float} amount
 * @param {Object} currencies
 * @param {String} detailCurrency
 * @param {number} [discount=0]
 *
 * @return {Array}
 */
export default function getNewLines(products, def, initLines, amount, currencies, detailCurrency, discount = 0) {
	/**
	 * Convert amount from one currency to another
	 * @param {Float} value
	 * @param {String} productCurrency
	 *
	 * @return {Float}
	 */
	const calcValueByCurrency = (value, productCurrency) => {
		let retValue = value;
  		if (detailCurrency === 'CZK' && productCurrency !== 'CZK') {
			retValue = value * currencies[productCurrency];
		} else if (detailCurrency !== 'CZK' && productCurrency === 'CZK') {
			retValue = value / currencies[detailCurrency];
		} else if (productCurrency === detailCurrency) {
			retValue = value;
		} else {
			retValue = (value / currencies[detailCurrency]) * (value * currencies[productCurrency]);
		}

		return retValue;
	};

	const newLines = initLines;
	console.log(newLines, 'newLines');
	let productBefore = null;
	products.forEach((product, i) => {
		const productCurrency = product.currency;
		const newProduct = {};
		Object.keys(def).forEach((key) => {
			if (def[key].fieldType === 'relate') {
				newProduct[key] = {
					id: product?.id,
					name: product?.product,
				};
			} else if (def[key].fieldType === 'float') {
				if (def[key].fieldName === 'amount') {
					newProduct[key] = (product[key] * amount || def[key]?.defaultValue * amount).toFixed(4);
				} else {
					let value = product[key] || def[key]?.defaultValue;
					value = Number.parseFloat(value).toFixed(4);
					newProduct[key] = value;
				}
			} else if (def[key].fieldType === 'currency'){
				let value = product[key] || def[key]?.defaultValue;
				newProduct[key] = calcValueByCurrency(value, productCurrency).toFixed(2);
			} else {
				newProduct[key] = product[key] || def[key]?.defaultValue;
			}
		});
		newProduct.photo = product.photo;
		newProduct.weight = product.weight;
		if (product.recId !== 0) {
			newProduct.isSubProductForProductId = productBefore?.mainProductId || productBefore?.isSubProductForProductId;
		} else {
			const mainProductId = Math.random() * 999999999999999;
			newProduct.isSubProductForProductId = 0;
			newProduct.mainProductId = mainProductId;
		}

		if (discount) {
			newProduct.discount = discount;
		}

		if (!newProduct.id) {
			newProduct.id = -Math.floor(Math.random() * 1000000000) - 1;
		}

		newLines.push(newProduct);
		productBefore = newProduct;
	});

	return newLines;
}

import React, { useState, useEffect, lazy, Suspense, useMemo, memo, useCallback } from 'react';
import useAction from '../../../../store/actions';
import ButtonBasic from '../../../Common/Buttons/ButtonBasic';
import utils from '../../../../utils';
import LinesGroupTotal from './LinesGroupTotal';
import LinesGroupHeader from './LinesGroupHeader';
import getNewLines from './linesFunctions/getNewLines';
import useCalculateLines from './linesFunctions/useCalculateLines';
import { Skeleton } from '@mui/material';
import LineRow from './LineRow';
import MemoizedGroup from './MemorizedGroup';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import FavoriteProducts from './FavoriteProducts';

/**
 *
 * @export
 * @param {Object} viewData
 * @param {Object} viewData
 * @param {Boolean} isPopup
 *
 * @return {*}
 */
export default function Lines({
	viewData,
	prefix,
	isPopup,
	loadLines,
	setLoadLines,
}) {
	const sAction = useAction();
	const [allRows, setAllRows] = useState([]);
	const [localGroups, setLocalGroups] = useState(viewData?.detail?.linesData?.lines);
	const [defaultGroup, setDefaultGroup] = useState({
		id: null,
		name: '',
		discount: 0,
		discountType: '%',
		note: '',
		rows: [],
	});
	const linesData = viewData?.detail?.linesData;
	const linesHaveGroups = viewData.module === 'quote';

	const currencies = sAction.dsGet('config/currencies');

	/***/
	const getDetailLines = () => {
		setLoadLines(true);
		utils
			.post('getDetailLines', {id: viewData.recordId, module: viewData.module})
			.then((res) => {
				sAction.initDetailLines(res.data.lines, prefix);
				setLoadLines(false);
			})
			.catch((error) => {
				console.error(error);
				setLoadLines(false);
			});
	};

	const initRecordId = isPopup ? viewData.recordId : utils.getRecordId();
	const initModuleName = isPopup ? viewData.module : utils.getModuleName();

	useEffect(() => {
		if (linesHaveGroups) {
			let groups = linesData.lines;

			if (groups.length === 0) {
				const newGroup = {...defaultGroup};
				newGroup.id =  -Math.floor(Math.random() * 1000000000);
				newGroup.name = '';
				newGroup.description = '';
				newGroup.rows = [];
				newGroup.discountType = '%';
				const newGroups = [newGroup];
				sAction.dsSet(`${prefix}/detail/linesData/lines`, newGroups);
			}

			setLocalGroups(linesData.lines);
		}
	}, [linesData.lines]);

	useEffect(() => {
		if (viewData.module === initModuleName && viewData.recordId === initRecordId) {
			getDetailLines();
		}
	}, [viewData.recordId, viewData.module]);

	useEffect(() => {
		setDefaultGroup({...defaultGroup, discount: viewData.detail?.fields?.discount?.value || 0});
	}, [viewData.detail?.fields?.discount?.value]);

	useEffect(() => {
		if (linesHaveGroups) {
			/**
 			 * @return {Array}
 			*/
			const getAllLinesRows = () => {
				let allRows = [];
				linesData?.lines?.map((group) => {
					allRows = [...allRows, ...group.rows];
				});

				return allRows;
			};

			setAllRows(getAllLinesRows());
		} else {
			setAllRows(linesData?.lines);
		}
	}, [linesData?.lines]);


	/**
 	 * @return {Array}
 	*/
	const addNewGroup = () => {
		setLocalGroups((prevGroups) => {
			const newGroup = {...defaultGroup};
			newGroup.id =  -Math.floor(Math.random() * 1000000000);
			newGroup.name = '';
			newGroup.description = '';
			newGroup.rows = [];
			newGroup.discountType = '%';
			const newLines = [...prevGroups, newGroup];

			sAction.dsSet(`${prefix}/detail/linesData/lines`, newLines);
		});
	};

	/**
	 * @param {Array} lines
	 *
 	 * @return {Array}
 	*/
	const initCalcLines = (lines) => {
		const newLines = lines;
		lines.forEach((currentLine, rowIndex) => {
			Object.keys(linesData.def).map((key) => {
				const lineDef = linesData.def[key];
				const taxRate = currentLine.taxRate;

				if (lineDef.formula) {
					let priceAfterDiscount = currentLine.discountType === '%'
            			? parseFloat(currentLine?.sellingPrice)?.toFixed(2) *((100 - parseFloat(currentLine?.discount)?.toFixed(2)) / 100)
            			: parseFloat(currentLine?.sellingPrice)?.toFixed(2) - parseFloat(currentLine?.discount)?.toFixed(2);

					priceAfterDiscount = parseFloat(priceAfterDiscount || currentLine.priceAfterDiscount || currentLine.sellingPrice)?.toFixed(2);
					const result = useCalculateLines(lineDef.formula, {
						...currentLine,
						priceAfterDiscount,
						taxRate,
					});

					newLines[rowIndex][key] = result;
				}
			});
		});

		return newLines;
	};

	/**
	 * @param {Object} product
	 */
	const getDataForLine = (product, groupIndex) => {
		sAction.openPopup(
			'AddDetailLine',
			'LBL_NEW_LINE_POPUP',
			false,
			{ recordId: product.id },
			(fetchData) => {
				const { amount, subProducts } = fetchData;
				sAction.load();
				const params = {
					module: 'product',
					recordId: product.id,
				};
				if (fetchData.variantId) {
					params.variantId = fetchData.variantId;
				}
				utils.post('getDataForLine', params).then((res) => {
					if (res.data?.length > 0) {
						if (linesHaveGroups) {
							let newLines = getNewLines(
								!subProducts ? [res.data[0]] : res.data,
								linesData.def,
								linesData.lines[groupIndex]?.rows,
								amount,
								currencies,
								viewData.detail.fields.currency?.value,
								parseFloat(viewData.detail.linesData?.lines?.[groupIndex]?.discount), // Group discount
							);
							newLines = initCalcLines(newLines);
							sAction.dsSet(`${prefix}/detail/linesData/lines/${groupIndex}/rows`, newLines);
						} else {
							let newLines = getNewLines(
								!subProducts ? [res.data[0]] : res.data,
								linesData.def,
								linesData.lines,
								amount,
								currencies,
								viewData.detail.fields.currency?.value
							);
							newLines = initCalcLines(newLines);
							sAction.dsSet(`${prefix}/detail/linesData/lines`, newLines);
						}
					}
					sAction.unload();
				});
			}
		);
	};

	/**
	 * @param {Number} groupIndex
	 *
 	 * @return {Array}
 	*/
	const addRow = (groupIndex) => {
		sAction.openPopup(
			'ListViewPopup',
			`LBL_${viewData.module.toUpperCase()}`,
			false,
			{ module: 'product', disableMultiple: true, fromModule: viewData.module },
			(product) => {
				setTimeout(() => {
					getDataForLine(product, groupIndex);
				});
			}
		);
	};

	const renderGroups = useCallback(() => {
		let groups = linesData.lines;
		return groups.map((group, i) => (
			<MemoizedGroup
				key={group.id}
				group={group}
				index={i}
				linesDef={linesData.def}
				prefix={prefix}
				linesData={linesData}
				module={viewData.module}
				linesHaveGroups={linesHaveGroups}
				addRow={addRow}
				allRows={allRows}
				addNewGroup={addNewGroup}
				initCalcLines={initCalcLines}
				discount={defaultGroup?.discount}
				linesLength={groups?.length}
				favoriteProducts={viewData.detail?.extraData?.favoriteProducts}
				getDataForLine={getDataForLine}
			/>
		));
	}, [linesData, linesData.lines, linesData?.lines?.length, defaultGroup, prefix, viewData.module, linesHaveGroups, allRows]);

	/**
 	 * @return {JSX}
 	*/
	const renderLinesTotal = () => {
		return Object.keys(linesData.def).map((key) => {
			return <td className="linesTotal__line" key={key} style={{ width: linesData.def[key]?.width }}></td>;
		});
	};

	/**
	 *
	 * @returns {JSX}
	*/
	const renderLines = useMemo(() => {
		return linesData?.lines?.map((row, index) => {
			return (
				<Suspense fallback={<tr><td colSpan={100}><Skeleton variant="rectangular" height={32} className="lineField__skeleton" /></td></tr>} key={row.id}>
					<LineRow
						row={row}
						module={viewData.module}
						rows={linesData.lines}
						prefix={prefix}
						rowIndex={index}
						linesDef={linesData.def}
						linesHaveGroups={linesHaveGroups}
						initCalcLines={initCalcLines}
					/>
				</Suspense>
			);
		});
	}, [linesData.lines, linesData.lines.length, viewData.module, prefix, linesData.def, linesHaveGroups]);

	const labelAddRow = utils.translate('LBL_ADD_ROW');

	/**
	 * @param {Event} event
	 *
	 * @returns {void}
	 */
	const handleDragRow = (event) => {
		const { over, active } = event;
		if (over) {
			const fromIndex = active.data.current.sortable.index;
			const toIndex = over.data.current.sortable.index;
			sAction.dsSet(`${prefix}/detail/linesData/lines`, utils.moveItemInArray(linesData.lines, fromIndex, toIndex));
		}
	};

	/**
	 * @param {Event} event
	 *
	 * @returns {void}
	 */
	const handleDragGroup = (event) => {
		const { over, active } = event;
		if (over) {
			const fromIndex = active.data.current.sortable.index;
			const toIndex = over.data.current.sortable.index;
			sAction.dsSet(`${prefix}/detail/linesData/lines`, utils.moveItemInArray(linesData.lines, fromIndex, toIndex));
		}
	};

	const favoriteProducts = viewData.detail?.extraData?.favoriteProducts;

	return (
		<div
			className={`lines ${!linesHaveGroups ? 'linesClassic' : ''}`}
		>
			{loadLines ? (
				<Skeleton className="linesGroup__load" variant="rectangular" height={200} />
			) : linesHaveGroups ? (
				<DndContext onDragEnd={handleDragGroup}>
					<SortableContext items={linesData.lines}>
						{renderGroups()}
					</SortableContext>
				</DndContext>
			) : (
				<>
					<div className="linesGroup__favoriteWrapper">
						{favoriteProducts && favoriteProducts?.length > 0 &&
						<FavoriteProducts
							getDataForLine={getDataForLine}
							favoriteProducts={viewData.detail?.extraData?.favoriteProducts}
						/>}
					</div>
					<div className="linesGroup__wrapper">
						<DndContext onDragEnd={handleDragRow}>
							<SortableContext items={linesData.lines}>
								<table className="linesGroup__table">
									<LinesGroupHeader linesHaveGroups={linesHaveGroups} def={linesData?.def} />
									<tbody>
										{!linesHaveGroups && renderLines}
										<tr>
											<td colSpan={4}>
												<ButtonBasic
													icon="addRecord"
													label={labelAddRow}
													className="linesGroup__addRow"
													transparent={true}
													onClick={() => addRow()}
												/>
											</td>
											<td></td>
										</tr>
										{!linesHaveGroups ? (
											<>
												<tr>
													<td className="linesRow__delete"></td>
													{renderLinesTotal()}
												</tr>
												<tr>
													<td colSpan={2}></td>
													<LinesGroupTotal rows={allRows} allLines={true} prefix={prefix} module={viewData.module} />
												</tr>
											</>
										) : null}
									</tbody>
								</table>
							</SortableContext>
						</DndContext>
					</div>
				</>
			)}
		</div>
	);
}

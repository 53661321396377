import {default as MuiSwitch} from '@mui/material/Switch';
import React from 'react';
import Tooltip from '../Common/Tooltip/Tooltip';

/**
 * @export
 * @param {Function} onChange
 * @param {Number} checked
 * @param {String} label
 * @param {String} className
 * @param {String} info
 *
 * @return {JSX}
 */
export default function Switch({
	onChange,
	checked,
	label,
	className,
	info,
}) {
	return (
		<div className={`switchWrapper ${className}`}>
			<MuiSwitch
				onChange={(e) => onChange(e)}
				checked={checked}
				className="switch"
			/>
			<span className="switch__label">{label}</span>
			{info ?
				<Tooltip placement="top" title={info}>
					<span className="fieldIcon iconfas-info"/>
				</Tooltip> : null}
		</div>
	);
}

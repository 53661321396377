import React from 'react';
import utils from '../../../utils';
import useAction from '../../../store/actions';

/**
 * @export
 * @param {Number | String} value
 * @param {String} className
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewFavorite({value, className, style, recordId, prefix, rowIndex}) {
	const sAction = useAction();

	/**
	 * Sets the favorite status in the store.
	 */
	const setStoreFavorite = () => {
		sAction.dsSet(`${prefix}/list/data/${rowIndex}/productFavorite`, value == 0 ? 1 : 0);
	};

	return (
		<td className={`listViewFavorite ${className}`} style={style}>
			<span onClick={() => {
				setStoreFavorite();
				utils.markFavorite('product', recordId, value == 0 ? 1 : 0)}
			} className={`${value == 0 ? 'iconfa-star' : 'iconfas-star'}`} />
		</td>
	);
}

import React, {useEffect, useState} from 'react';
import ToastSlice from './ToastSlice';
import useAction from '../../store/actions';

// Depracated remove this after some time of no use
export default function Toast({slices}) {
	const sAction = useAction();

	const closeSlice = (index) => {
		sAction.destroyToastSlice(index);
	};

	const renderToast = () => {
		return slices.map((slice, i) => {
			const timeoutId = setTimeout(() => {
				closeSlice(i);
			}, 5000);

			return <ToastSlice
				timeoutId={timeoutId}
				index={i} key={i}
				title={slice.title}
				description={slice.description}
				type={slice.type}
				closeSlice={closeSlice}
			/>;
		});
	};
	if (slices?.length > 0) {
		return (
			<div className="app__toast">
				{renderToast()}
			</div>
		);
	} else {
		return null;
	}
}
import utils from '.';

/**
 *
 *
 * @export
 * @param {String} module
 * @param {String | Number} id
 */
export default function markFavorite(module, recordId, value, callback) {
	utils.post('markFavorite', {module, recordId, value})
		.then((res) => {
			if (callback) {
				callback(res);
			}
		})
		.catch((error) => {
			console.error(error);
		});
}

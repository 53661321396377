import React, {useState, useEffect} from 'react';
import {TextField} from '@mui/material';
import utils from '../../../utils';

export default function SearchBox({onChangeSearch, module, searchTimeout}) {
	const [timerId, setTimerId] = useState(null);
	const [value, setValue] = useState('');
	const [lastSearchValue, setLastSearchValue] = useState('');

	useEffect(() => {
		setValue('');
	}, [module]);

	const goSearch = (e) => {
		onChangeSearch(e);
		setLastSearchValue(e);
	};

	const handleChange = (e) => {
		setValue(e.target.value);

		if (!searchTimeout) {
			return goSearch(e);
		}

		if (timerId) {
			clearTimeout(timerId);
		}

		const newTimerId = setTimeout(() => {
			goSearch(e.target.value);
		}, 1000);

		setTimerId(newTimerId);
	};

	const cancelSearch = (e) => {
		if (timerId) {
			clearTimeout(timerId);
		}

		if (lastSearchValue != '') {
			goSearch('');
		}

		setValue('');
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13 && searchTimeout) {
			if (timerId) {
				clearTimeout(timerId);
			}

			goSearch(value);
		}
	};

	return (
		<div className="searchBox__container">
			<TextField
				error={false}
				required={false}
				placeholder={utils.translate('LBL_SEARCH')}
				className="searchBox classicInput"
				onChange={(e) => handleChange(e)}
				onKeyDown={(e) => handleKeyDown(e)}
				value={value ? value : ''}
				name="search"
				label={''}
				type="text"
				disabled={false}
				id="searchBox"
				inputProps={{
					readOnly: false,
					maxLength: 32,
				}}
			/>
			{value != '' ? (
				<span className="iconfas-cross searchBox__remove-icon" onClick={(e) => cancelSearch(e)}></span>
			) : (
				<span className="iconfas-search searchBox__search-icon"></span>
			)}
		</div>
	);
}

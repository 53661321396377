import React, {useState, useEffect} from 'react';
import ButtonReport from '../Common/Buttons/ButtonReport';
import useAction from '../../store/actions';
import utils from '../../utils';
import Loader from '../Common/Loader/Loader';
import toast from 'react-hot-toast';
import Table from './components/Table';
import ChartBar from './components/ChartBar';

/**
 * @export
 * @param {Object} reportDataBase
 * @param {Object} translate
 * @return {JSX}
 */
export default function Report({reportDataBase, translate}) {
	const sAction = useAction();
	const [reportData, setReportData] = useState({});
	const [filterValues, setFilterValues] = useState({});
	const [refreshTime, setRefreshTime] = useState('');
	const [load, setLoad] = useState(false);

	/**
	 * @param {Object} filters
	 * @param {Boolean} loadReport
	 */
	const callReportWithFilters = (filters, loadReport) => {
		if (loadReport) {
			setLoad(true);
		}
		utils
			.post('getReport', {filters: filters, module: 'report', reportId: reportDataBase?.id})
			.then((res) => {
				if (res.data !== 0) {
					setReportData(res?.data);
				} else {
					toast.error(res?.dataResult);
				}
				setRefreshTime(utils.formatDate());
				setLoad(false);
			})
			.catch((error) => {
				console.error(error);
				setLoad(false);
			});
	};

	useEffect(() => {
		let filterValues = {};
		if (reportDataBase?.filter) {
			Object.keys(reportDataBase?.filter)?.forEach((key) => {
				const field = reportDataBase?.filter[key];
				if (field?.type !== 'break') {
					const field = reportDataBase?.filter[key];
					filterValues = {...filterValues, [field.name]: field?.defaultValue};
				}
			});

			setFilterValues(filterValues);
		}
		if (reportDataBase?.id) {
			callReportWithFilters(filterValues, true);
		}
	}, [reportDataBase?.id]);

	/**
	 * @returns {Void}
	 */
	const openFilters = () => {
		sAction.openPopup('ReportFiltersPopup', 'LBL_FILTERS', false, {filters: reportDataBase?.filter, filterValues: filterValues}, (filters) => {
			setFilterValues(filters);
			callReportWithFilters(filters);
		});
	};

	/**
	 * @param {String} type
	 * @returns {Void}
	 */
	const printReport = (type) => {
		utils
			.post('printReport', {reportId: reportData?.id || reportDataBase?.id, module: 'report', type, filters: filterValues})
			.then((res) => {
				if (res.data) {
					window.open(res?.data, '_blank');
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const lastRefreshLabel = utils.translate('LBL_LAST_REFRESH_DATE');

	if (!reportDataBase?.id) {
		return;
	}

	return (
		<div className="report">
			<div className="report__header">
				<div className="report__header-name">
					<div>{translate?.[reportDataBase?.name] || reportDataBase?.name}</div>
					<div className="report__header-time">
						<span>{lastRefreshLabel}:</span>
						<span>{refreshTime}</span>
					</div>
				</div>
				<div className="report__header-actions">
					{reportDataBase?.filter ? <ButtonReport onClick={() => openFilters()} icon={'filter'} label={'LBL_FILTERS'} /> : null}
					{reportDataBase?.pdf == 1 ? <ButtonReport onClick={() => printReport('pdf')} iconColor={'#DC3545'} icon={'pdf'} /> : null}
					{reportDataBase?.xls == 1 ? <ButtonReport onClick={() => printReport('xls')} iconColor={'#28A745'} icon={'xls'} /> : null}
					{reportDataBase?.csv == 1 ? <ButtonReport  onClick={() => printReport('csv')} icon={'csv'} /> : null}
				</div>
			</div>
			<div className="report__content">
				{load ? <div className="loadBoxCenter"><Loader /></div> :
					<>
						{reportData?.filter ? <div className="report__filter" dangerouslySetInnerHTML={{__html: reportData?.filter}} /> : null}
						{reportData?.chartBar ? <ChartBar data={reportData?.chartBar?.data} colors={reportData?.chartBar?.colors} lines={reportData?.chartBar?.lines} /> : null}
						{reportData?.sum ? <Table headerLabel={'LBL_REPORT_SUM'} columns={reportData?.sum?.columns} data={reportData?.sum?.data} total={reportData?.sum?.total} /> : null}
						{reportData?.table ? <Table headerLabel={'LBL_REPORT_TABLE'} columns={reportData?.table?.columns} data={reportData?.table?.data} /> : null}
					</>
				}
			</div>
		</div>
	);
}

/**
 * @export
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export default function initList(state, action) {
	if (action.payload.prefix !== 'view') {
		const place = action.payload.prefix.split('/')[0];
		const itemIndex = parseInt(action.payload.prefix.split('/')[1], 10);
		if (!isNaN(itemIndex) && itemIndex >= 0 && itemIndex < state[place].length) {
			const updatedPopups = [...state[place]];
			updatedPopups[itemIndex] = {
				...updatedPopups[itemIndex],
				list: {
					...state.list,
					...action.payload,
				},
			};

			const retValue =  {
				...state,
				[place]: updatedPopups,
			};
			return retValue;
		}
	} else {
		return {...state, view: {
			...state.view,
			list: {
				...state.list,
				...action.payload,
			}
		}};
	}
}

import React from 'react';
import { Popover as MuiPopover } from '@mui/material';
import utils from '../../../utils';

/**
 * @export
 * @param {Number | String} id
 * @param {Boolean} open
 * @param {Ref} anchor
 * @param {Array} options
 * @param {Object} anchorOrigin
 * @param {Object} transformOrigin
 *
 * @return {*}
 */
export default function Popover({
	id,
	open,
	anchor,
	options,
	anchorOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
}) {
	/**
	 * @return {JSX}
	 */
	const renderOption = () => {
		return options?.map((item, i) => {
			const label = utils.translate(item?.label);
			if (item.hidden) {
				return null;
			}
			return (
				<div onClick={() => {
					item.onClick && !item.disabled ? item.onClick(item) : null;
				}} key={i} className={`popover__item ${item.disabled ? 'popover__item-disabled' : ''}`}>
					<div className={`iconfas-${item.icon} popover__item-icon`} />
					<div>{label}</div>
				</div>
			);
		});
	};

	return (
		<MuiPopover
			id={id}
			open={open}
			anchorEl={anchor}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			className="popover">
			{renderOption()}
		</MuiPopover>
	);
}

import React from 'react';
import ListViewLink from './ListViewFields/ListViewLink';
import ListViewEnum from './ListViewFields/ListViewEnum';
import ListViewNumber from './ListViewFields/ListViewNumber';
import ListViewFileLink from './ListViewFields/ListViewFileLink';
import ListViewEditableNumber from './ListViewFields/ListViewEditableNumber';
import ListViewTextHtml from './ListViewFields/ListViewTextHtml';
import ListViewCheckbox from './ListViewFields/ListViewCheckbox';
import ListViewPrint from './ListViewFields/ListViewPrint';
import ListViewFavorite from './ListViewFields/ListViewFavorite';

/**
 * @export
 * @param {Object} fieldData
 * @param {Object} headerColumn
 * @param {String} module
 * @param {Number | String} rowId
 * @param {String} name
 * @param {Function} popupSelectCallback
 * @param {String} recordName
 * @param {Number | String} parentId
 * @param {Boolean} disableMultiple
 * @param {Number | String} variantId
 * @param {Boolean} disabledRecords
 * @param {String} prefix
 * @param {Number} rowIndex
 *
 * @return {*}
 */
export default function ListViewField({
	fieldData, // Most of the time it is value but it is like fileLink we ned some data so it is object
	headerColumn,
	module,
	rowId,
	name,
	popupSelectCallback,
	recordName,
	parentId,
	disableMultiple,
	variantId,
	disabledRecords,
	prefix,
	rowIndex,
}) {
	const hideOnDashboard = headerColumn?.hideOnLowRes && prefix.includes('dashboard');
	if (hideOnDashboard) {
		return null;
	}

	const columnWidth = headerColumn?.columnWidth;
	const width = window.innerWidth >= 900 ? {width: columnWidth} : {minWidth: columnWidth};
	const style = {...width, ...headerColumn?.style};
	let additionalClass = headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : '';

	switch (headerColumn?.fieldType) {
	case 'link':
	case 'relateSelect':
		return (
			<ListViewLink
				className={`listView__cell ${additionalClass}`}
				key={`${rowId}_${headerColumn?.columnName}_${module}`}
				data={fieldData}
				style={style}
				module={module}
				popupSelectCallback={popupSelectCallback}
				disableMultiple={disableMultiple}
				name={name}
				recordName={recordName}
				disabledRecords={disabledRecords}
			/>
		);
	case 'select':
		return (
			<ListViewEnum
				className={`listView__cell ${additionalClass}`}
				value={fieldData}
				name={name}
				options={headerColumn?.options}
				prefix={prefix}
				style={style}
			/>
		);
	case 'number':
	case 'float':
	case 'currency':
		return (
			<ListViewNumber
				className={`listView__cell ${headerColumn?.fieldType} ${additionalClass}`}
				value={fieldData}
				style={style}
			/>
		);
	case 'fileLink':
		return (
			<ListViewFileLink
				className={`listView__cell ${additionalClass}`}
				path={fieldData?.value}
				recordId={rowId}
				module={module}
				recordName={recordName}
				style={style}
			/>
		);
	case 'checkbox':
		return (
			<ListViewCheckbox
				className={`listView__cell ${headerColumn?.fieldType} ${additionalClass}`}
				value={fieldData}
				style={style}
			/>
		);
	case 'editableNumber':
		return (
			<ListViewEditableNumber
				className={`listView__cell ${additionalClass}`}
				value={fieldData}
				id={rowId}
				parentId={parentId}
				module={module}
				variantId={variantId}
				// style={style}
			/>
		);
	case 'textHtml':
		return (
			<ListViewTextHtml
				className={`listView__cell ${additionalClass}`}
				value={fieldData}
				style={style}
			/>
		);
	case 'print':
		return (
			<ListViewPrint
				className={`listView__cell ${additionalClass}`}
				value={fieldData}
				style={style}
				icon={headerColumn?.icon}
				module={module}
				id={rowId}
				columnName={headerColumn?.columnName}
			/>
		);
	case 'favorite':
		return (
			<ListViewFavorite
				className={`listView__cell ${additionalClass}`}
				value={fieldData}
				style={style}
				recordId={rowId}
				prefix={prefix}
				rowIndex={rowIndex}
			/>
		);
	default:
		return (
			<td key={`${rowId}_${headerColumn?.columnName}_${module}`} style={style} className={`listView__cell ${additionalClass}`}>
				{typeof fieldData === 'object' ? fieldData?.value : fieldData}
			</td>
		);
	}
}

import React, {useEffect, useState} from 'react';
import useAction from '../../store/actions';
import BasicCheckbox from '../FormElements/BasicCheckbox';
import ListViewField from './ListViewField';

export default function listViewRow({
	rowData,
	viewData,
	popupSelectCallback,
	prefix,
	disableMultiple,
	disabledRecords,
	subpanelKey,
	rowIndex,
}) {
	const [checked, setChecked] = useState(false);
	const sAction = useAction();

	const selectedRecords = sAction.dsGet(`${prefix}/list/selected`) || [];
	useEffect(() => {
		if (selectedRecords?.includes(rowData.id.toString()) || selectedRecords.includes(rowData.id)) {
			setChecked(true);
		}
	}, []);

	useEffect(() => {
		if (selectedRecords?.includes(rowData.id.toString()) || selectedRecords.includes(rowData.id)) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [selectedRecords?.length]);

	const handleCheck = () => {
		setChecked(!checked);
		let updatedListSelected;
		if (!checked) {
		  updatedListSelected = [...selectedRecords, rowData.id.toString()];
		} else {
		  updatedListSelected = selectedRecords.filter((id) => id.toString() !== rowData.id.toString());
		}

		sAction.dsSet(`${prefix}/list/selected`, updatedListSelected);
	};

	const renderFields = () => {
		return viewData?.list?.header?.map((headerColumn) => {
			return (
				<ListViewField
					headerColumn={headerColumn}
					key={`${rowData.id}_${headerColumn.columnName}_${viewData.module}`}
					fieldData={rowData[headerColumn.columnName]}
					rowId={rowData.id}
					module={viewData.module}
					name={headerColumn.columnName}
					popupSelectCallback={popupSelectCallback}
					disableMultiple={disableMultiple}
					recordName={rowData?.name?.value || rowData?.name}
					disabledRecords={disabledRecords}
					prefix={prefix}
					subpanelKey={subpanelKey}
					rowIndex={rowIndex}
				/>
			);
		});
	};

	return (
		<tr className="listView__row" key={rowData.id}>
			<td className="listView__cell-checkbox">
				{!disableMultiple && !disabledRecords?.includes(rowData?.id) && <BasicCheckbox size="small" color="blue" checked={checked ? checked : false} onClick={handleCheck} />}
			</td>
			{renderFields()}
			<td className="listView__cell"></td>
		</tr>
	);
}

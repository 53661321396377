import { useSelector } from 'react-redux';

/**
 * Get translation for label
 * @param {string} labelKey - label for translation
 * @returns {string}
 */
export default function useTranslate(labelKey){
	const translates = useSelector(state => state?.config?.translate?.label);
	return translates?.[labelKey] || labelKey;
}